import './Logo.scss';
import Link from 'next/link';

const Logo = () => (
	<Link href="/">
		<div id="logo">
			<span className="mobile">DB</span>
			<span className="full">Dan Blaax</span>
		</div>
	</Link>
);

export default Logo;

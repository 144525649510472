const TrackDisplay = ({ artist, track }) => {
	if (!track) return null;
	const { name } = track;
	return (
		<div id="artist-and-track">
			<img src={artist.img_url} />
			<div id="description">
				<div id="track-name">{name}</div>
				<div id="artist-name">{artist.name}</div>
			</div>
		</div>
	);
};

export default TrackDisplay;

const PauseButton = () => (
	<svg
		width="46px"
		height="46px"
		viewBox="0 0 46 46"
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
	>
		<g
			id="Page-1"
			stroke="none"
			strokeWidth="1"
			fill="none"
			fillRule="evenodd"
		>
			<g
				id="iPhone-XR"
				transform="translate(-197.000000, -751.000000)"
				fill="#FFFFFF"
				stroke="#FFFFFF"
				strokeWidth="5"
			>
				<g id="Group-2" transform="translate(200.000000, 754.000000)">
					<rect
						id="Rectangle"
						x="0"
						y="0"
						width="12"
						height="40"
						rx="1"
					></rect>
					<rect
						id="Rectangle"
						x="28"
						y="0"
						width="12"
						height="40"
						rx="1"
					></rect>
				</g>
			</g>
		</g>
	</svg>
);

export default PauseButton;

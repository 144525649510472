import './WhoIsSection.scss';

const WhoIsSection = () => (
	<div id="who-is-section">
		<div className="text-content">
			<div className="col-3">
				<h2>About Dan</h2>
				<p>
					They call him the “The Maverick of the sax world” Lighting
					up Ministry of Sound, Niki Beach and La Folie Douce club
					venues around the world. Dan Blaax is known for his epic
					saxophone melodies, bringing club tracks to a whole new
					dimension on stage.
				</p>
				<p>
					"The bigger the venue the better, as I’ve got an epic melody
					for every occasion!"
				</p>
				<p>
					For bookings:{' '}
					<a href="mailto:info@danblaax.com">info@danblaax.com</a>
				</p>
			</div>
		</div>
		<div className="image-content">
			<img src="/images/dan.jpg" alt="Dan Blaax" />
		</div>
	</div>
);

export default WhoIsSection;

import './EnterTheClub.scss';
import Link from 'next/link';
import { featureFlags } from '../../config';
import { AudioPlayer, VideoPlayer } from '../index';
import SongsList from '../songs-list/SongsList';
import VideosList from '../videos-list/VideosList';
import useStores from '../../helpers/useStores';
import { observer } from 'mobx-react';

const plans = [
	{
		name: 'Bronze',
		currency: 'GBP',
		price: 699,
		duration: '/mo',
		features: [
			{ name: 'Listen to songs', available: true },
			{ name: 'Watch Videos', available: false },
			{ name: 'Download songs', available: false },
			{ name: 'Download videos', available: false },
		],
		signupUrlQuery: 'bronze',
	},
	{
		name: 'Silver',
		currency: 'GBP',
		price: 1099,
		duration: '/mo',
		features: [
			{ name: 'Listen to songs', available: true },
			{ name: 'Watch Videos', available: true },
			{ name: 'Download songs', available: false },
			{ name: 'Download videos', available: false },
		],
		signupUrlQuery: 'silver',
	},
	{
		name: 'Gold',
		currency: 'GBP',
		price: 1299,
		duration: '/mo',
		features: [
			{ name: 'Listen to songs', available: true },
			{ name: 'Watch Videos', available: true },
			{ name: 'Download songs', available: true },
			{ name: 'Download videos', available: true },
		],
		signupUrlQuery: 'gold',
	},
];

const saxpackPlan = {
	name: 'Saxpack',
	currency: 'GBP',
	price: 799,
	duration: '/mo',
	signupUrlQuery: 'saxpack',
};

const Feature = ({ name, available }, index) => (
	<li className={available ? 'available' : 'not-available'} key={index}>
		{name}
	</li>
);

const currencies = {
	GBP: '£',
};

const displayPrice = (price, currency) => {
	if (currency === 'GBP') return price / 100;
	return price;
};

const PriceDisplay = ({ price, duration, currency }) => {
	const currencySymbol = currencies[currency];
	return (
		<div className="price-display">
			<span className="currency">{currencySymbol}</span>
			<span className="price">{displayPrice(price, currency)}</span>
			<span className="duration">{duration}</span>
		</div>
	);
};

const Plan = (
	{ name, price, duration, currency, features, signupUrlQuery },
	index
) => (
	<div className="plan" key={index}>
		<h3>{name}</h3>
		<ul>{features.map(Feature)}</ul>
		<PriceDisplay price={price} duration={duration} currency={currency} />
		<Link href={'/signup/?plan=' + signupUrlQuery}>
			<a className="button gold">Sign me up</a>
		</Link>
	</div>
);

const saxpack = featureFlags.enableSaxpack ? (
	<div id="saxpack-plan">
		<h2>The Danblaax Saxpack</h2>
		<p>
			Download audio file samples from Dan Blaax for using in your tracks.
		</p>
		<PriceDisplay
			price={saxpackPlan.price}
			duration={saxpackPlan.duration}
			currency={saxpackPlan.currency}
		/>
		<Link href={'/signup/?plan=' + saxpackPlan.signupUrlQuery}>
			<a className="button gold">Sign me up</a>
		</Link>
	</div>
) : null;

const EnterTheClub = observer(() => {
	const { exampleTrackStore } = useStores();
	return (
		<div id="enter-the-club">
			<div className="content">
				<h1>Enter the Club</h1>
				<p>Become a member and get the Dan Blaax experience 24/7</p>
				<h2>Songs</h2>
				<SongsList
					songs={exampleTrackStore.tracks}
					currentTrack={exampleTrackStore.currentTrack}
					onClick={exampleTrackStore.setCurrentTrack}
					allowDownload={false}
				/>
				<h2>Videos</h2>
				<VideosList
					videos={exampleTrackStore.tracks}
					onClick={exampleTrackStore.setCurrentVideo}
				/>
				<p id="extra-details">
					To enter the membership area with{' '}
					<b>loads more songs and videos</b>, choose a plan.
				</p>
				<h2>Choose a plan</h2>
				<div id="plans">{plans.map(Plan)}</div>
				{saxpack}
			</div>
			<AudioPlayer
				track={exampleTrackStore.currentTrack}
				startPlaying={!!exampleTrackStore.currentTrack}
				pauseAsVideoIsPlaying={!!exampleTrackStore.currentVideo}
			/>
			<VideoPlayer
				video={exampleTrackStore.currentVideo}
				onClick={() => exampleTrackStore.setCurrentVideo(null)}
			/>
		</div>
	);
});

export default EnterTheClub;

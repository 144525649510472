import sessionStore from '../src/stores/sessionStore';
import { featureFlags } from '../src/config';

const homeLink = {
	text: 'Home',
	url: '/',
	hideOnDesktop: true,
	hideOptions: () => true,
};

// const shopLink = {
// 	text: 'Shop',
// 	url: 'https://danblaax.myshopify.com',
// 	target: '_blank',
// 	rel: 'noopener noreferrer',
// 	hideOptions: () => true,
// };

const signupLink = {
	text: 'Join today',
	url: '/join',
	hideOptions: ({ loggedIn }) => !loggedIn,
};

const loginLink = {
	text: 'Login',
	url: '/login',
	hideOptions: ({ loggedIn }) => !loggedIn,
};

const logoutLink = {
	text: 'Logout',
	id: 'logout',
	onClick: () => sessionStore.delete(),
	hideOptions: ({ loggedIn }) => loggedIn,
};

const links = featureFlags.enableSignup
	? [homeLink, signupLink, loginLink, logoutLink]
	: [homeLink, loginLink, logoutLink];

export default links;

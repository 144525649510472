// Dependencies
import React from 'react';
// import Link from 'next/link';
import Router from 'next/router';

import { observer } from 'mobx-react';
import useStores from '../../helpers/useStores';
import { useForm } from 'react-hook-form';
import { FormField, Input, handleErrors } from '@anephenix/ui';

// Helpers
import { redirectIfLoggedIn } from '../../helpers/redirect';

// Styling
import './LoginForm.scss';

const LoginForm = observer(() => {
	const { handleSubmit, register, errors, setError } = useForm();
	const { sessionStore, errorStore } = useStores();
	const onSubmit = async (values) => {
		try {
			await sessionStore.create(values);
			Router.push({ pathname: '/dashboard' });
			return;
		} catch (err) {
			errorStore.handle(new Error('Login failed'));
			handleErrors(setError, err);
		}
	};

	const identifierField = (
		<FormField error={errors?.identifier?.message}>
			<label htmlFor="identifier">Username / Email</label>
			<Input
				name="identifier"
				ref={register}
				type="text"
				placeholder="Username / Email"
			/>
		</FormField>
	);

	const passwordField = (
		<FormField error={errors?.password?.message}>
			<label htmlFor="password">Password</label>
			<Input
				name="password"
				ref={register}
				type="password"
				placeholder="Password"
			/>
		</FormField>
	);

	return redirectIfLoggedIn(
		useStores(),
		<div id="LoginPage" className="page">
			<div className="content">
				<form
					className="form"
					id="login"
					onSubmit={handleSubmit(onSubmit)}
				>
					<h1>Login</h1>
					{identifierField}
					{passwordField}
					<button type="submit">Login</button>
				</form>
			</div>
		</div>
	);
});

export default LoginForm;

import Song from '../song/Song';
import './SongsList.scss';
const SongsList = ({ songs, onClick, currentTrack, allowDownload }) => {
	let allowDownloadHeader = null;
	if (allowDownload) {
		allowDownloadHeader = <th></th>;
	}
	return (
		<table cellSpacing="0" id="songs">
			<thead>
				<tr>
					<th></th>
					<th>Title</th>
					{allowDownloadHeader}
					<th>Length</th>
				</tr>
			</thead>
			<tbody>
				{songs.map((song) => {
					return (
						<Song
							key={song.id}
							{...{ song, currentTrack, onClick, allowDownload }}
						/>
					);
				})}
			</tbody>
		</table>
	);
};

export default SongsList;

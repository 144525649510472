import './Button.scss';

const Button = ({ type, href, text, className }) => {
	const classNameValue = `button ${className}`;
	if (!type) return <div className={classNameValue}>{text}</div>;
	if (type === 'a')
		return (
			<a href={href} className={classNameValue}>
				{text}
			</a>
		);
	if (type === 'button')
		return <button className={classNameValue}>{text}</button>;
};

export default Button;

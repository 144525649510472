import './Recording.scss';
import { Button } from '../../components';

const Recording = ({ video }) => (
	<div id="recordingPage" className="content">
		<h1>Live Recording</h1>
		<video
			id="recording"
			src={video.src}
			poster={video.img}
			controls={true}
		/>
		<div id="recording-options">
			<Button type="a" text="Listen" />
			<Button type="a" className="disabled" text="Watch" />
			<Button type="a" className="disabled" text="Download" />
		</div>
	</div>
);

export default Recording;

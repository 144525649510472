import './WhatIsProductSection.scss';
import Link from 'next/link';
import { featureFlags } from '../../config';

const Details = () => {
	if (!featureFlags.enableSignup) return <p>Coming soon!</p>;
	return (
		<>
			<p>
				Get access to songs and music videos from Dan Blaax, wherever
				you are:
			</p>
			<ul>
				<li>Listen to songs online</li>
				<li>Watch videos of those songs</li>
				<li>Download the songs and videos to your device</li>
			</ul>
			<Link href="/join">
				<a className="button" id="join">
					Join today
				</a>
			</Link>
		</>
	);
};

const WhatIsProductSection = () => (
	<div id="what-is-product-section">
		<div className="content">
			<h2>Enter the club</h2>
			<Details />
		</div>
	</div>
);

export default WhatIsProductSection;

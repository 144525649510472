import './DownArrow.scss';

const DownArrow = ({
	width = '60px',
	height = '60px',
	stroke = '#ffffff99',
}) => (
	<div id="down-arrow">
		<svg
			width={width}
			height={height}
			viewBox="0 0 99 99"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<g id="Homepage" stroke="none" fill="none" fillRule="evenodd">
				<g
					id="Homepage---hero-element"
					transform="translate(-671.000000, -870.000000)"
					stroke={stroke}
					strokeWidth="2"
				>
					<g id="Group" transform="translate(672.000000, 871.000000)">
						<circle id="Oval" cx="48.5" cy="48.5" r="48.5" />
						<polyline
							id="Path"
							strokeLinecap="round"
							strokeLinejoin="round"
							points="28 39 48.16 59 70 39"
						/>
					</g>
				</g>
			</g>
		</svg>
	</div>
);

export default DownArrow;

import trackLength from '../../helpers/trackLength';

const Song = ({ song, currentTrack, onClick, allowDownload }) => {
	const { id, name, time_length } = song;
	const classNames =
		currentTrack && currentTrack.id === id ? 'song current' : 'song';
	let allowDownloadBody = null;
	if (allowDownload)
		allowDownloadBody = (
			<td>
				<a
					className="download-song"
					href={song.song_url}
					target="_blank"
					rel="noopener noreferrer"
					download={song.name}
				>
					Download Song
				</a>
			</td>
		);
	return (
		<tr
			key={id}
			className={classNames}
			onClick={() => {
				onClick(song);
			}}
		>
			<td>
				<img src="/images/icons/play.svg" alt="play" />
			</td>
			<td>{name}</td>
			{allowDownloadBody}
			<td>{trackLength(time_length)}</td>
		</tr>
	);
};

export default Song;

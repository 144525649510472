// Dependencies
import React, { useState, useEffect } from 'react';
import { observer, MobXProviderContext } from 'mobx-react';
import { Logo } from '../index';
import { NavBar } from '@anephenix/ui';
import './NavBar.scss';

function useStores() {
	return React.useContext(MobXProviderContext);
}

const NavBarWrapper = observer(({ links }) => {
	const stores = useStores();
	const sessionStore = stores.sessionStore;
	const { hasLoaded, session } = sessionStore;
	const loggedIn = hasLoaded && session;
	const [hasScrolled, setHasScrolled] = useState('');

	const checkScroll = () => {
		if (window.scrollY >= 80) {
			setHasScrolled('scrolled');
		} else {
			setHasScrolled('');
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', checkScroll);
		// return window.removeEventListener('scroll', checkScroll);
	});

	return (
		<NavBar
			logo={<Logo />}
			className={hasScrolled}
			links={links}
			loggedIn={loggedIn}
		/>
	);
});

export default NavBarWrapper;

import './VideosList.scss';

import trackLength from '../../helpers/trackLength';

const Video = ({ video, onClick }) => {
	const { id, name, poster_url, time_length } = video;
	return (
		<div
			key={id}
			className="video"
			onClick={() => {
				onClick(video);
				window.scroll({
					top: 0, // could be negative value
					left: 0,
					behavior: 'smooth',
				});
			}}
		>
			<img
				className="thumbnail"
				src={poster_url}
				alt={`post image for ${name}`}
			/>
			<div className="details">
				<div className="name">{name}</div>
				<div className="time_length">{trackLength(time_length)}</div>
			</div>
		</div>
	);
};

const VideosList = ({ videos, onClick }) => {
	return (
		<div id="videos">
			{videos.map((video) => {
				return <Video key={video.id} {...{ video, onClick }} />;
			})}
		</div>
	);
};

export default VideosList;

import trackLength from '../../helpers/trackLength';

const TrackTimeLengthDisplay = ({ duration, currentTime, onClick }) => (
	<div id="time-track-length-display">
		<div id="current-time">{trackLength(currentTime)}</div>
		<div
			id="time-bar"
			onClick={(event) => {
				const pointOfClick = event.clientX;
				const barCoordinates = event.target.getBoundingClientRect();
				const percentageLength = parseInt(
					(100 / barCoordinates.width) *
						(pointOfClick - barCoordinates.x)
				);
				onClick(percentageLength);
			}}
		>
			<div id="time-bar-full-length"></div>
			<div
				id="time-bar-progress-length"
				style={{ width: `${(100 / duration) * currentTime}%` }}
			></div>
		</div>
		<div id="time-remaining">-{trackLength(duration - currentTime)}</div>
	</div>
);

export default TrackTimeLengthDisplay;

const PlayButton = ({ width, height }) => {
	if (!width) width = 46;
	if (!height) height = 56;
	return (
		<svg
			width={`${width}px`}
			height={`${height}px`}
			viewBox="0 0 46 56"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<g
				id="Page-1"
				stroke="none"
				strokeWidth="1"
				fill="none"
				fillRule="evenodd"
				strokeLinejoin="round"
			>
				<g
					id="iPhone-XR"
					transform="translate(-197.000000, -691.000000)"
					fill="#FFFFFF"
					stroke="#FFFFFF"
					strokeWidth="5"
				>
					<polygon
						id="Triangle"
						transform="translate(220.000000, 719.000000) rotate(-270.000000) translate(-220.000000, -719.000000) "
						points="220 699 245 739 195 739"
					></polygon>
				</g>
			</g>
		</svg>
	);
};

export default PlayButton;

import './Footer.scss';

const Footer = () => {
	const year = new Date().getFullYear();
	return (
		<div id="footer">
			<div className="content">
				<span>&copy; {year} Dan Blaax</span>
				<a href="mailto:blaaxmusic@gmail.com">Contact support</a>
			</div>
		</div>
	);
};

export default Footer;

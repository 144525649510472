import './VideoPlayer.scss';
import HLS from 'hls.js';
import { useEffect } from 'react';

const VideoPlayer = ({ video, onClick }) => {
	useEffect(() => {
		if (HLS.isSupported()) {
			const videoElement = document.getElementById('video-element');
			const hls = new HLS();
			hls.attachMedia(videoElement);
			hls.on(HLS.Events.MEDIA_ATTACHED, function () {
				hls.loadSource(video.video_url);
			});
		}
	}, [video]);

	if (!video) return null;

	let downloadVideoButton = null;

	if (video.video_download_url) {
		downloadVideoButton = (
			<a
				href={video.video_download_url}
				target="_blank"
				rel="noopener noreferrer"
				download={video.name}
				id="download-video"
			>
				Download
			</a>
		);
	}

	return (
		<div id="video-player-background">
			{downloadVideoButton}
			<div id="exit-video" onClick={onClick}>
				Exit
			</div>
			<div id="video-player">
				<video id="video-element" controls>
					<source src={video.video_url} />
				</video>
			</div>
		</div>
	);
};

export default VideoPlayer;

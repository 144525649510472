import './ErrorMessage.scss';

const ErrorMessage = ({ error, onClose }) => {
	if (!error) return null;
	return (
		<div id="error-message">
			<span>{error.message || 'An error occurred'}</span>
			<div className="close" onClick={onClose}>
				×
			</div>
		</div>
	);
};

export default ErrorMessage;

const trackLength = length => {
	if (length === null || length === undefined) return 'N/A';
	const minute = 60;
	const minutes = Math.floor(length / minute);
	const seconds = length % minute;
	const visualSeconds = seconds < 10 ? `0${seconds}` : seconds;
	return `${minutes}:${visualSeconds}`;
};

export default trackLength;

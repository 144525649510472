const VolumeControl = ({ onClick, volume }) => {
	const width = `${volume}%`;

	const onClickAction = (event) => {
		const pointOfClick = event.clientX;
		const barCoordinates = event.target.getBoundingClientRect();
		const percentageLength = parseInt(
			(100 / barCoordinates.width) * (pointOfClick - barCoordinates.x)
		);
		onClick(percentageLength);
	};

	const LowVolumeIcon = () => (
		<svg
			width="13px"
			height="20px"
			viewBox="0 0 13 20"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<g
				transform="translate(-943.000000, -932.000000)"
				fill="#FFFFFF"
				id="Combined-Shape"
			>
				<path d="M943,946 L943,938 L948,938 L956,932 L956,952 L948,946 L943,946 Z"></path>
			</g>{' '}
		</svg>
	);

	const HighVolumeIcon = () => (
		<svg
			width="26px"
			height="20px"
			viewBox="0 0 26 20"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<g transform="translate(-1146.000000, -932.000000)" id="Group-4">
				<g transform="translate(1146.000000, 932.000000)">
					<path
						d="M0,14 L0,6 L5,6 L13,0 L13,20 L5,14 L0,14 Z"
						id="Combined-Shape"
						fill="#FFFFFF"
					></path>
					<path
						d="M17,5 C15.6666667,8.62666667 15.6666667,11.96 17,15"
						id="Path"
						stroke="#FFFFFF"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
						transform="translate(16.500000, 10.000000) scale(-1, 1) translate(-16.500000, -10.000000) "
					></path>
					<path
						d="M21,3 C18.3333333,8.07733333 18.3333333,12.744 21,17"
						id="Path"
						stroke="#FFFFFF"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
						transform="translate(20.000000, 10.000000) scale(-1, 1) translate(-20.000000, -10.000000) "
					></path>
					<path
						d="M25,1 C22.3333333,7.528 22.3333333,13.528 25,19"
						id="Path"
						stroke="#FFFFFF"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
						transform="translate(24.000000, 10.000000) scale(-1, 1) translate(-24.000000, -10.000000) "
					></path>
				</g>
			</g>
		</svg>
	);

	return (
		<div id="volume-controls">
			<LowVolumeIcon />
			<div id="volume-bar" onClick={onClickAction}>
				<div id="volume-bar-full-length"></div>
				<div id="volume-bar-current-level" style={{ width }}></div>
			</div>
			<HighVolumeIcon />
		</div>
	);
};

export default VolumeControl;

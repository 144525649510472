import './Hero.scss';
import DownArrow from '../down-arrow/DownArrow';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const Hero = ({ scrollTo }) => (
	<div id="hero">
		<div id="main-content">
			<p>Welcome to the world of Dan Blaax</p>
			<AnchorLink href={scrollTo}></AnchorLink>
		</div>
		<AnchorLink href={scrollTo}>
			<DownArrow />
		</AnchorLink>
	</div>
);

export default Hero;

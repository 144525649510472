// Dependencies
import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import useStores from '../../helpers/useStores';
import { useForm } from 'react-hook-form';
import { useRouter } from 'next/router';
import { featureFlags } from '../../config';
import {
	Input,
	Dropdown,
	Checkbox,
	FormField,
	handleErrors,
} from '@anephenix/ui';

import { injectStripe } from 'react-stripe-elements';

// Helpers
import { redirectIfLoggedIn } from '../../helpers/redirect';

// Services
import { userService } from '../../services';

// Styling
import './SignupForm.scss';

const SignupForm = observer(({ stripe }) => {
	const { handleSubmit, register, errors, setError } = useForm();
	const { errorStore } = useStores();
	// Use this for setting the disable button state
	const [disableButton, setDisableButton] = useState(false);
	const [defaultValueForSelect, setDefaultValueForSelect] = useState();
	const [
		defaultValueForReferralCode,
		setDefaultValueForReferralCode,
	] = useState();
	const [loaded, setLoaded] = useState(false);
	const router = useRouter();

	const onSubmit = async (values) => {
		try {
			setDisableButton(true);

			const { data } = await userService.create(values);
			await stripe.redirectToCheckout({
				sessionId: data.stripeCheckoutSessionId,
			});
			return;
		} catch (err) {
			errorStore.handle(new Error('Signup failed'));
			handleErrors(setError, err);
			setDisableButton(false);
		}
	};

	// const acceptedTermsLabel = (
	// 	<span>
	// 		I accept the{' '}
	// 		<a
	// 			id="terms"
	// 			href="/terms"
	// 			rel="noopener noreferrer"
	// 			target="_blank"
	// 		>
	// 			Terms of Service
	// 		</a>
	// 	</span>
	// );

	const signupForNewsletterLabel = (
		<span>I would like to receive the newsletter</span>
	);

	useEffect(() => {
		const { plan, referral_code } = router.query;
		setDefaultValueForSelect(plan);
		setDefaultValueForReferralCode(referral_code);
		setLoaded(true);
	});

	// TODO - it would be good to be able to load these values programmatically from the API in some way
	const planOptions = [
		{ value: 'bronze', text: 'Bronze - £6.99/mo' },
		{ value: 'silver', text: 'Silver - £10.99/mo' },
		{ value: 'gold', text: 'Gold - £12.99/mo' },
	];

	if (featureFlags.enableSaxpack) {
		planOptions.push({ value: 'saxpack', text: 'Saxpack - £7.99/mo' });
	}

	const usernameField = (
		<FormField error={errors?.username?.message}>
			<Input name="username" placeholder="Username" ref={register} />
		</FormField>
	);

	const emailField = (
		<FormField error={errors?.email?.message}>
			<Input
				type="email"
				name="email"
				placeholder="Email"
				ref={register}
			/>
		</FormField>
	);

	const passwordField = (
		<FormField error={errors?.password?.message}>
			<Input
				type="password"
				name="password"
				placeholder="Password"
				ref={register}
			/>
		</FormField>
	);

	// const acceptedTermsField = (
	// 	<FormField error={errors?.accepted_terms?.message}>
	// 		<Checkbox
	// 			label={acceptedTermsLabel}
	// 			name="accepted_terms"
	// 			ref={register}
	// 		/>
	// 	</FormField>
	// );

	const signupForNewsletterField = (
		<FormField error={errors?.newsletter?.message}>
			<Checkbox
				label={signupForNewsletterLabel}
				name="newsletter"
				ref={register}
			/>
		</FormField>
	);

	const selectPlanField = () => {
		if (!loaded && !defaultValueForSelect) return null;
		return (
			<FormField error={errors?.plan?.message}>
				<Dropdown
					name="plan"
					ref={register}
					defaultValue={defaultValueForSelect}
					options={planOptions}
				/>
			</FormField>
		);
	};

	const referralCodeField = () => {
		if (!loaded && !defaultValueForReferralCode) return null;
		return (
			<>
				<label>Got a referral code?</label>
				<FormField error={errors?.referral_code?.message}>
					<Input
						name="referral_code"
						placeholder="Referral Code"
						defaultValue={defaultValueForReferralCode}
						ref={register}
					/>
				</FormField>
			</>
		);
	};

	return redirectIfLoggedIn(
		useStores(),
		<div id="SignupForm" className="page">
			<div className="content">
				<form
					className="form"
					id="signup"
					onSubmit={handleSubmit(onSubmit)}
				>
					<h1>Signup</h1>
					{usernameField}
					{emailField}
					{passwordField}
					<h2>Select a plan</h2>
					<label>Plan</label>
					{selectPlanField()}
					{referralCodeField()}
					{signupForNewsletterField}
					<button type="submit" disabled={disableButton}>
						Proceed to payment
					</button>
				</form>
			</div>
		</div>
	);
});

export default injectStripe(SignupForm);

import './Dashboard.scss';
import SongsList from '../songs-list/SongsList';
import trackLength from '../../helpers/trackLength';

const Video = ({ video, onClick }) => {
	const { id, name, poster_url, time_length } = video;
	return (
		<div
			key={id}
			className="video"
			onClick={() => {
				onClick(video);
				window.scroll({
					top: 0, // could be negative value
					left: 0,
					behavior: 'smooth',
				});
			}}
		>
			<img
				className="thumbnail"
				src={poster_url}
				alt={`post image for ${name}`}
			/>
			<div className="details">
				<div className="name">{name}</div>
				<div className="time_length">{trackLength(time_length)}</div>
			</div>
		</div>
	);
};

const VideosList = ({ videos, onClick }) => {
	return (
		<div id="videos">
			{videos.map((video) => {
				return <Video key={video.id} {...{ video, onClick }} />;
			})}
		</div>
	);
};

const Dashboard = ({ songs, currentTrack, onClick, onClickVideo }) => {
	let showVideos = null;
	// Check if payload includes video_url in songs
	if (songs && songs.length > 0 && songs[0].video_url) {
		showVideos = (
			<>
				<h1>Videos</h1>
				<VideosList videos={songs} onClick={onClickVideo} />
			</>
		);
	}

	// NOTE - in the future, there has to be a better way
	const allowDownload = songs
		? songs.length > 0 && songs[0].allowDownload
		: false;

	return (
		<div id="dashboardPage" className="container">
			<div className="withSidePadding">
				<h1>Songs</h1>
				<SongsList
					songs={songs}
					currentTrack={currentTrack}
					onClick={onClick}
					allowDownload={allowDownload}
				/>
				{showVideos}
			</div>
		</div>
	);
};

export default Dashboard;

import { Fragment, useState, useEffect } from 'react';
import Head from 'next/head';
import './AudioPlayer.scss';
import PlayButton from './PlayButton';
import PauseButton from './PauseButton';
import { observer } from 'mobx-react';
import TrackTimeLengthDisplay from './TrackTimeLengthDisplay';
import TrackDisplay from './TrackDisplay';
import VolumeControl from './VolumeControl';

const Audio = ({ src }) => {
	return (
		<audio>
			Your browser does not support the
			<code>audio</code> element.
			<source src={src} type="audio/mp3" />
		</audio>
	);
};

const PlayOrPause = ({ playState, setPlayState }) => {
	const button = playState ? <PauseButton /> : <PlayButton />;
	return <div onClick={() => setPlayState(!playState)}>{button}</div>;
};

const AudioPlayer = ({ track, pauseAsVideoIsPlaying }) => {
	const [playState, setPlayState] = useState(false);
	const [duration, setDuration] = useState(0);
	const [currentTime, setCurrentTime] = useState(0);
	const [volume, setVolume] = useState(100);
	// Note - this needs updating when changing to a new track
	const audio = track ? <Audio src={track.song_url} /> : null;

	const showPlayer = track ? true : false;

	useEffect(() => {
		if (pauseAsVideoIsPlaying) setPlayState(false);
		const audioElement = document.querySelector('audio');
		if (audioElement) audioElement.volume = volume / 100;

		const updateTimes = () => {
			setDuration(parseInt(audioElement.duration));
			setCurrentTime(parseInt(audioElement.currentTime));
		};

		if (track) {
			if (audioElement.src !== track.song_url)
				audioElement.src = track.song_url;
			audioElement.ondurationchange = updateTimes;
			audioElement.ontimeupdate = updateTimes;
			audioElement.onended = () => {
				setPlayState(false);
			};
		}

		if (playState) {
			audioElement.play();
		} else {
			audioElement && audioElement.pause();
		}
	}, [track, playState, volume, pauseAsVideoIsPlaying]);

	if (!showPlayer) return null;

	const artist = {
		name: 'Dan Blaax',
		img_url: '/images/artist-icon.png',
	};

	const audioElement = document.querySelector('audio');
	const setTimeTo = (percentage) => {
		audioElement.currentTime = audioElement.duration * (percentage / 100);
	};
	return (
		<Fragment>
			<Head>
				<meta name="apple-mobile-web-app-capable" content="yes" />
				<meta
					name="apple-mobile-web-app-status-bar-style"
					content="black"
				/>
				<meta name="theme-color" content="#000"></meta>
			</Head>
			<div id="audio-player">
				{audio}
				<TrackDisplay track={track} artist={artist} />
				<TrackTimeLengthDisplay
					duration={duration}
					currentTime={currentTime}
					onClick={setTimeTo}
				/>
				<div id="audio-controls">
					<PlayOrPause
						playState={playState}
						setPlayState={setPlayState}
					/>
				</div>
				<VolumeControl volume={volume} onClick={setVolume} />
			</div>
		</Fragment>
	);
};

export default observer(AudioPlayer);
